import React, { useEffect, useState, forwardRef } from "react";
import GrayCard from "../gray-card/GrayCard";
import ContentWrapper from "../content-wrapper/ContentWrapper";
import Heading from "../heading/Heading";
import TermContent from "./TermContent";
import { Checkbox } from "../checkbox/Checkbox";
import { SlSizeFullscreen } from "react-icons/sl";
import { cn } from "../../../utils/tailwindMerger";

interface TermCardProps {
  term: Term;
  numOfTerms?: number;
  showFullScreenTerms: (term: Term) => void;
  index: number;
  onCheckboxChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  checkedStates: any;
}

const TermCard = forwardRef<HTMLDivElement, TermCardProps>(
  (
    {
      term,
      index,
      checkedStates,
      numOfTerms,
      onCheckboxChange,
      showFullScreenTerms,
    },
    ref
  ) => {
    const [h1Content, setH1Content] = useState("");
    const [termsContent, setTermsContent] = useState("");

    useEffect(() => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(term.contents, "text/html");
      const h1 = doc.querySelector("h1");
      const h1Text = h1 ? h1.textContent : "";
      if (h1) {
        h1.remove();
      }
      const remainingHTML = doc.body.innerHTML;
      if (index === 1) {
        setH1Content("General Terms and Conditions");
      } else {
        setH1Content(h1Text!);
      }

      setTermsContent(remainingHTML);
    }, [term.contents, index]);

    return (
      <GrayCard
        noPadding
        ref={ref}
        className={cn(
          "md:max-h-[55vh] max-h-[60vh] py-6 overflow-hidden",
          index % 2 === 0 && index + 1 === numOfTerms
            ? "lg:col-span-2"
            : "lg:col-span-1"
        )}
      >
        <ContentWrapper className="flex flex-col h-full">
          <Heading size="sm">{h1Content}</Heading>
          <div className="bg-white lg:overflow-auto overflow-hidden flex-[1] p-3 rounded-md shadow-[0px_0px_0px_0.2px_rgb(0_0_0_/_0.2)] my-6 relative">
            <div className="sticky top-0 right-5 flex justify-end">
              <div
                onClick={() => showFullScreenTerms(term)}
                role="button"
                className="bg-[rgba(0,0,0,0.5)] p-1 text-white text-sm rounded"
              >
                <SlSizeFullscreen
                  className="cursor-pointer"
                  onClick={() => showFullScreenTerms(term)}
                />
              </div>
            </div>
            <TermContent content={termsContent} />
          </div>
          <div className="flex-[0] flex items-end">
            {term.assertion && (
              <Checkbox
                id={index.toString()}
                checked={checkedStates[index]}
                label={
                  <span dangerouslySetInnerHTML={{ __html: term.assertion }} />
                }
                onChange={(e) => {
                  onCheckboxChange(index, e);
                }}
              />
            )}
          </div>
        </ContentWrapper>
      </GrayCard>
    );
  }
);

export default TermCard;
