import { createSlice } from "@reduxjs/toolkit";

interface ProfileState {
  profile: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
      middleName: string;
    };
  };
}

const initialState: ProfileState = {
  profile: {
    email: "",
    name: {
      firstName: "",
      lastName: "",
      middleName: "",
    },
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { fetchProfile } = profileSlice.actions;
export default profileSlice.reducer;
