import React from "react";
import { SuccessMessage } from "src/components";

export const SuccessPage = () => {
  return (
    <div className="flex justify-center w-full h-full  ">
      <div
        className="w-full flex justify-center  bg-white py-8 rounded-xl h-[67vh] md:h-[70%] 2xl:h-[80vh] md:mt-3 mt-4  overflow-auto px-4 md:px-0
      "
      >
        <div className="md:w-2/3 flex justify-center items-center">
          <SuccessMessage
            heading="Success!"
            label="Your Right to Work check has been successfully submitted. You will be contacted via email if additional information is required to complete your check."
          />
        </div>
      </div>
    </div>
  );
};
