import * as React from "react";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FaceDetectionWebComponent,
  FaceLivenessDetailType,
  FaceLivenessWebComponent,
  IFaceDetection,
  IFaceLiveness,
} from "@regulaforensics/vp-frontend-face-components";
import { setDocCaptureData } from "../../../../store/slices/identityDocCapture.ts";
import { useDispatch } from "react-redux";
import { ImageKey } from "../../../models/image-key.enum.ts";

export interface SelfieCaptureProps {
  useFaceLiveness?: boolean;
  onSelfieCaptureFinished?: (imageData: any) => void;
  onSelfieCaptureModalClosed: () => void;
}

declare global {
  interface HTMLElementTagNameMap {
    "face-liveness": FaceLivenessWebComponent;
    "face-capture": FaceDetectionWebComponent;
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "face-liveness": DetailedHTMLProps<
        IFaceLiveness & HTMLAttributes<FaceLivenessWebComponent>,
        FaceLivenessWebComponent
      >;
      "face-capture": DetailedHTMLProps<
        IFaceDetection & HTMLAttributes<FaceDetectionWebComponent>,
        FaceDetectionWebComponent
      >;
    }
  }
}

export const SelfieCapture = ({
  useFaceLiveness,
  onSelfieCaptureFinished,
  onSelfieCaptureModalClosed,
}: SelfieCaptureProps) => {
  const [isOpen, setIsOpen] = useState(useFaceLiveness);
  const containerRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<FaceLivenessWebComponent>(null);

  const dispatch = useDispatch();

  const listener = useCallback(
    (data: CustomEvent<FaceLivenessDetailType>) => {
      if (data.detail.action === "PROCESS_FINISHED") {
        if (data.detail.data?.status === 1 && data.detail.data.response) {
          if (onSelfieCaptureFinished) {
            onSelfieCaptureFinished(data.detail.data.response.images[0]);
            dispatch(
              setDocCaptureData({
                selfieImage: {
                  format: "base64",
                  source: data.detail.data.response.images[0],
                  key: ImageKey.Selfie,
                },
              }),
            );
          }
        }
      }

      if (
        data.detail?.action === "CLOSE" ||
        data.detail?.action === "RETRY_COUNTER_EXCEEDED"
      ) {
        setIsOpen(false);
        onSelfieCaptureModalClosed();
      }
    },
    [dispatch, onSelfieCaptureFinished, onSelfieCaptureModalClosed],
  );

  useEffect(() => {
    if (isOpen && componentRef.current) {
      componentRef.current.settings = {
        customization: {
          onboardingScreenStartButtonBackground: "#5b5050",
        },
      };
    }
  }, [isOpen]);

  useEffect(() => {
    const containerCurrent = containerRef.current;

    if (!containerCurrent) return;

    containerCurrent.addEventListener("face-liveness", listener);

    return () => {
      containerCurrent.removeEventListener("face-liveness", listener);
    };
  }, [listener]);

  return (
    <div ref={containerRef} className="box-border rounded-3xl md:max-w-[43rem]">
      {isOpen ? (
        <face-liveness
          ref={componentRef}
          className="font-inter text-[#1d85db] hover:text-[#1664a4] active:text-[#1d85db] rounded-3xl box-border"
        ></face-liveness>
      ) : (
        ""
      )}
    </div>
  );
};
