import {
  DatePicker,
  LocationSearch,
  TextInput,
  Title,
} from "@simplecitizen/gdl-react-ui-components";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import { useAppSelector } from "src/hooks/redux-hooks";

interface ExperianQuestionsProps {
  control: any;
}

const ExperianQuestions = ({ control }: ExperianQuestionsProps) => {
  const { field: primaryResidentialAddressField } = useController({
    name: "primaryResidentialAddress",
    control,
  });

  const { field: dateOfBirthField } = useController({
    name: "dateOfBirth",
    control,
  });
  const fields = useMemo(() => {
    return [
      {
        label: "Primary residential address",
        // name: "primaryResidentialAddress",
        type: "location-search",
        onSelectLocation: (value: any) => {
          primaryResidentialAddressField.onChange(
            value.geo_results[0].formatted_address
          );
        },
      },
      {
        label: "Date of Birth",
        name: "dateOfBirth",
        type: "date",

        value: new Date(dateOfBirthField?.value || ""),
        onDateChange: (date: Date) => {
          dateOfBirthField.onChange(date.toISOString());
        },
      },
    ];
  }, [dateOfBirthField, primaryResidentialAddressField]);
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  console.log({ ffa: dateOfBirthField.value });
  return (
    <div className="w-full mt-6">
      <Title className="mt-3 mb-3">Experian Questions</Title>
      <div className="flex flex-col">
        {fields.map(({ name, type, ...field }) => (
          <div key={name} className="">
            {type === "text" && (
              <TextInput
                className=""
                labelClassName="font-normal "
                {...field}
              />
            )}
            {type === "location-search" && (
              <LocationSearch
                resultsContainerClassName="top-[4.3rem]"
                color={client?.branding?.primaryColor || "#0A71C7"}
                key={name}
                className=""
                labelClassName="font-normal"
                {...field}
              />
            )}
            {type === "date" && (
              <DatePicker
                datePickerClassName="top-[4.3rem]"
                className="-mt-2"
                color={client?.branding?.primaryColor || "#0A71C7"}
                labelClassName="font-normal"
                onDateChange={field.onDateChange!}
                value={field.value as Date}
                label={field.label}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperianQuestions;
