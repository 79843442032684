import React, { useEffect, useRef } from "react";

const SuccessCheckmark = () => {
  const checkmarkRef: any = useRef(null);

  useEffect(() => {
    const checkmark = checkmarkRef.current;
    if (checkmark) {
      checkmark.classList.add("animate");
    }
  }, []);

  return (
    <div className="md:w-[20%] md:h-[20%] w-[40%] h-[40%] ">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle className="checkmark-circle" cx="26" cy="26" r="25" />
        <path className="checkmark-check" d="M14 27l10 10 16-16" />
      </svg>
    </div>
  );
};

export default SuccessCheckmark;
