import React, { ComponentProps } from "react";

type CheckboxProps = ComponentProps<"input"> & {
  checked: boolean;
  label: any;

  id?: string | number;
};

export const Checkbox = ({
  checked,
  label,
  onChange,

  id,
  ...props
}: CheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <label htmlFor={id} className="flex items-center relative">
      <input
        id={id ?? ""}
        type="checkbox"
        className="h-4 w-4"
        checked={checked}
        onChange={handleChange}
        {...props}
      />

      {label && (
        <div className="flex pl-4  text-black font-inter text-sm font-normal md:cursor-pointer">
          {label}
        </div>
      )}
    </label>
  );
};
