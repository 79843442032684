import {
  DocumentUpload,
  Heading,
  StackLoader,
} from "@simplecitizen/gdl-react-ui-components";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux-hooks";
import { uploadNonIdentityDocument } from "src/services/apiService";
import { addIdentityDoc } from "src/store/slices/identityDocCapture";
import axios, { AxiosProgressEvent } from "axios";
import { useParams } from "react-router-dom";
import { addDoc } from "src/store/slices/uploadedNonIdDocsSlice";
import { CameraFacingMode } from "@regulaforensics/vp-frontend-document-components";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useSWR from "swr";

const SWR_RETRY_COUNT = 5;

type ErrorObject = {
  [key: string]: {
    en: string;
  };
};

export type FieldError = {
  fieldName: string;
  error: string;
};

interface DocumentUploaderCardProps {
  removeDocument: (
    document: DocumentCollector,
    docToRemove: "nonIdentityDocuments" | "identityDocuments"
  ) => void;
  document: DocumentCollector;
}

interface UploadedImage {
  format: string;
  key: string;
  source: string;
}

interface ApiErrorResponse {
  title: string;
  status: number;
  document: null | string;
  errors: {
    [key: string]: {
      [lang: string]: string;
    };
  };
  warnings: {};
}

const DocumentUploaderCard = ({
  removeDocument,
  document,
}: DocumentUploaderCardProps) => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { checkId } = useParams<{ checkId: string }>();
  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileUrl, setMobileUrl] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  /////// GENERATE SESSION ID
  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  /////// GENERATE MOBILE URL
  useEffect(() => {
    const url = `${window.location.origin}/document-upload/${checkId}/mobile-handoff/${document._id}/${sessionId}?startScreen=${encodeURIComponent(!document?.config?.newDocumentRequired)}&facialComparison=${encodeURIComponent(document?.config?.facialComparison)}&documentName=${encodeURIComponent(document.name.en!)}`;

    setMobileUrl(url);
  }, [location, checkId, document, sessionId]);

  const MAX_UPLOAD_ATTEMPTS = parseInt(
    process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "3",
    10
  );

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL ||
    "https://localhost:5003/api/workright";
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  /**
   * Converts a string by removing "data.", dots, and hyphens, and converting to lowercase.
   * @param {string} str - The string to convert.
   * @returns {string} - The processed string in lowercase without "data.", dots, or hyphens.
   */
  function toLowerNoSeparators(str: string) {
    return str
      .replace(/^data[.-]/, "")
      .replace(/[.-]/g, "")
      .toLowerCase();
  }

  /**
   * Converts an object of errors to an array of FieldError objects.
   * @param {ErrorObject} errors - The error object to convert.
   * @returns {FieldError[]} - Array of FieldError objects.
   */
  function convertErrorsToFieldErrors(errors: ErrorObject): FieldError[] {
    return Object.keys(errors).map((key) => ({
      fieldName: toLowerNoSeparators(key),
      error: errors[key].en,
    }));
  }

  const handleApiErrors = (errorResponse: ApiErrorResponse) => {
    const errorMessages = Object?.values(errorResponse?.errors)?.flatMap(
      (errorDetail) => Object?.values(errorDetail)
    );
    setServerErrors(errorMessages);
  };

  const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(
    null
  );

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [docKey, setDocKey] = useState(null);
  const handleFileChange = async (files: File[]): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", "front");

      const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 0)) * 100
          );
          setUploadProgress(progress);
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document?._id}/images`,
        formData,
        config
      );

      setUploadedImage(data);
      // setFileUploadError(null);
    } catch (error) {
      console.error("An error occurred while uploading the file", error);
    } finally {
    }
  };

  const [checkIfDocsUploaded, setCheckIfDocsUploaded] = useState(false);
  const [startFetching, setStartFetching] = useState(false);
  const [mobileUploadStatus, setMobileUploadStatus] = useState<
    "success" | "error" | "loading"
  >("loading");
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    // Check if device is mobile by screen width (example threshold: 768px)
    const isMobileDevice = window.innerWidth <= 1023;

    setIsMobile(isMobileDevice);
  }, []);

  // Delay starting the fetch by `x` milliseconds after `checkIfDocsUploaded` is true
  useEffect(() => {
    if (checkIfDocsUploaded && !isMobile) {
      const delay = setTimeout(() => {
        setStartFetching(true);
      }, 3 * 1000); // x is the number of seconds you want to delay

      // Clean up the timeout if `canFetchData` becomes false
      return () => clearTimeout(delay);
    } else {
      setStartFetching(false);
    }
  }, [checkIfDocsUploaded, isMobile]);

  const fetcher = async (url: string) => {
    const response = await fetch(url);
    return response.json();
  };

  useSWR(
    startFetching
      ? `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/mobile-handoff-sessions/${sessionId}`
      : null,
    fetcher,
    {
      refreshInterval: 2000,
      errorRetryCount: SWR_RETRY_COUNT,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= SWR_RETRY_COUNT) {
          setMobileUploadStatus("error");
          return;
        }
        if (error.status === 404) return;
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      onSuccess: () => {
        setMobileUploadStatus("success");
      },
    }
  );

  ///////// SUBMIT ID DOC TO THE API

  const verifyDocument = async ({ docFrontSideImage }: any) => {
    setDocKey(null);
    setServerErrors([]);
    setFieldErrors([]);
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage }],
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/Validate`,
        newDocument
      );

      setDocKey(response.data._id);
    } catch (error: any) {
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );

        setFieldErrors(field_errors);
      }

      handleApiErrors(error.response.data);
    }
  };

  /////////FORCE SUBMIT ID DOC TO THE API

  const verifyForcedDocument = async ({ docFrontSideImage }: any) => {
    setDocKey(null);
    setServerErrors([]);
    setIsLoading(true); // Start the loader
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage }],
      status: "created",
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${document._id}/Validate`,
        newDocument
      );

      setDocKey(response.data._id);
    } catch (error: any) {
      handleApiErrors(error.response.data);
    } finally {
      setIsLoading(false); // End the loader after the request completes
    }
  };

  //////// SUBMIT NON ID DATA TO THE API
  const submitNonIdDoc = async ({
    documentNumber,
    dateOfExpiry,
    issuingStateName,
    documentFile,

    image,
  }: any) => {
    // if (uploadProgress > 0 && uploadProgress !== 100) return;

    if (submitting) return;
    setServerErrors([]);

    const dataToSend = [
      {
        slug: "document-number",
        name: {
          en: "Document Number",
        },
        value: documentNumber,
        source: "workright-employee-experience",
      },
      {
        slug: "date-of-expiry",
        name: {
          en: "Date of Expiry",
        },
        value:
          typeof dateOfExpiry === "string"
            ? ""
            : dateOfExpiry?.toLocaleDateString(),
        source: "workright-employee-experience",
      },
      {
        slug: "issuing-state-name",
        name: {
          en: "Issuing State name",
        },
        value: issuingStateName,
        source: "workright-employee-experience",
      },
    ];
    const imagesToSend = [uploadedImage];
    setIsSubmitting(true);
    try {
      const uploadedDocuments = await uploadNonIdentityDocument(
        document?._id!,
        checkId!,
        {
          data: dataToSend,
          images: imagesToSend,
        }
      );

      dispatch(addDoc({ ...document, doc_key: uploadedDocuments._id }));
    } catch (error: any) {
      handleApiErrors(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="mb-1 font-semibold  ">
        <span className="mr-1">Upload</span>
        {document.name.en}{" "}
        <span className="">{document.config.required ? "*" : ""}</span>
      </div>

      <DocumentUpload
        isDocumentUploadedOnMobile={mobileUploadStatus === "success"}
        onChangeToDesktopUploader={() => {
          setCheckIfDocsUploaded(false);
        }}
        mobileUploadStatus={mobileUploadStatus}
        onOpenUploader={() => {
          if (document._subtype === "identity") {
            setMobileUploadStatus("loading");
            setCheckIfDocsUploaded(true);
          }
        }}
        onClose={() => {
          if (document._subtype === "identity") {
            setCheckIfDocsUploaded(false);
          }
        }}
        onForceRegulaDocument={async (fields, rawData) => {
          dispatch(addIdentityDoc({ ...document, doc_key: docKey }));
          if (serverErrors?.length !== 0) {
            await verifyForcedDocument({ docFrontSideImage: uploadedImage });
          }
        }}
        facialComparison={document?.config?.facialComparison}
        uploadAttempts={MAX_UPLOAD_ATTEMPTS}
        mobileHandOff={true}
        mobileUploadUrl={mobileUrl || ""}
        regulaStartScreen={!document?.config?.newDocumentRequired}
        // regulaStartScreen={true}
        regula_settings={{
          regulaLogo: false,
          changeCameraButton: true,
          cameraMode: "environment" as CameraFacingMode,
          multipageProcessing: true,
        }}
        fieldErrors={fieldErrors}
        onReplace={() => {}}
        verifyDocument={async ({ docFrontSideImage }, rawData) => {
          const base64Data = docFrontSideImage.split(",")[1];
          setUploadedImage(base64Data);
          await verifyDocument({ docFrontSideImage: base64Data });
        }}
        verifyOnProcessingDocument={true}
        onStoreRegulaDocument={async (fields, rawData) => {
          dispatch(addIdentityDoc({ ...document, doc_key: docKey }));
          if (serverErrors?.length !== 0) {
            await verifyForcedDocument({ docFrontSideImage: uploadedImage });
          }
        }}
        documentReaderProcessParams={{
          returnUncroppedImage: false,
        }}
        title={`Upload ${document.name.en}`}
        documentName={`${document.name.en}`}
        onRemoveDocument={() => {
          if (document._subtype === "identity") {
            return removeDocument(document, "identityDocuments");
          }
          removeDocument(document, "nonIdentityDocuments");
        }}
        onFileChange={async (files) => {
          await handleFileChange(files);
        }}
        uploadProgress={uploadProgress}
        onStoreDefaultDocument={async (docData) => {
          await submitNonIdDoc(docData);
        }}
        serverErrors={serverErrors!}
        identityType={document._subtype as "default" | "identity"}
        color={
          client && client?.branding?.primaryColor
            ? client?.branding?.primaryColor
            : "#14BDF3"
        }
      />

      {isLoading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.7)] z-10 flex justify-center items-center">
          <div className="bg-white w-96 h-96 flex justify-center items-center rounded-lg overflow-hidden">
            {/* <div className="loader"></div> */}
            <div className="flex flex-col justify-center items-center py-48">
              <div className="grid gap-3 mb-6">
                <StackLoader />
              </div>
              <div>
                <Heading>Verifying...</Heading>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploaderCard;
