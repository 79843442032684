import { createSlice } from "@reduxjs/toolkit";
//import type { PayloadAction } from '@reduxjs/toolkit'

export interface PageState {
  page: number;
}

const initialState: PageState = {
  page: 1,
};

export const pageNumberSlice = createSlice({
  name: "page_number",
  initialState,
  reducers: {
    nextPage: (state) => {
      state.page += 1;
    },
    prevPage: (state) => {
      state.page -= 1;
    },
  },
});
export const { nextPage, prevPage } = pageNumberSlice.actions;

export default pageNumberSlice.reducer;
