import React, { useEffect, useState } from "react";
import { TermsComponent } from "../../components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PassCodeBox from "src/components/lib/terms/PassCodeBox.tsx";
import { useAppSelector } from "src/hooks/redux-hooks.ts";
import { privacy_policy } from "src/components/lib/terms/privacy-policy";

export const TsAndCsPage = ({ scrollToNextPage }: any) => {
  const tsAndcs = useSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?.terms ?? ""
  );
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  const [checkedStates, setCheckedStates] = useState(
    [...tsAndcs?.filter((term: any) => term.contents != null), "_"]?.map(
      () => false
    )
  );

  const { checkId } = useParams<{ checkId: string }>();
  const [isDisabled, setIsDisabled] = useState(true);

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = event.target.checked;
    setCheckedStates(newCheckedStates);
  };

  useEffect(() => {
    const checkedAll = checkedStates.every((item: boolean) => item);

    setIsDisabled(!checkedAll);
  }, [checkedStates]);

  return (
    <div className="flex items-center justify-center md:pt-2 pt-4">
      <div className="w-full space-y-6">
        <div className="space-y-4">
          <TermsComponent
            terms={[
              {
                contents: privacy_policy,
                assertion: `<div>I consent to the
<a class="text-primary" href="https://www.fragomen.com/privacy-policies-notices-and-statements.html" target="blank">Fragomen Privacy Policy</a></div>`,
              },
              ...tsAndcs,
            ]}
            checkedStates={checkedStates}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>

        <PassCodeBox
          isDisabled={isDisabled}
          scrollToNextPage={scrollToNextPage}
          checkId={checkId!}
          backgroundColor={client?.branding?.primaryColor}
        />
      </div>
    </div>
  );
};
