import {
  SelectInput,
  TextInput,
  Title,
} from "@simplecitizen/gdl-react-ui-components";

import { UseFormRegister } from "react-hook-form";

interface RequiredDataProps {
  selectedStatusType: StatusType | null;
  register: UseFormRegister<any>;
}

const RequiredData = ({ selectedStatusType, register }: RequiredDataProps) => {
  return (
    <div>
      <Title className="mt-3 mb-3">Required information</Title>
      <div className="flex flex-col">
        {selectedStatusType?.dataCollectors?.map((dataCollector) => {
          return (
            <div key={dataCollector._id}>
              {dataCollector._subtype === "select" && (
                <SelectInput
                  labelClassName="font-normal"
                  label={dataCollector.label}
                  options={dataCollector.options}
                  {...register(dataCollector.key)}
                />
              )}
              {dataCollector._subtype === "text" && (
                <TextInput
                  label={dataCollector.label}
                  {...register(dataCollector.key)}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequiredData;
