import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";

// Initial state
const initialState: FormState = {
  profile: {
    firstName: "",
    middleName: "",
    lastName: "",
  },
  jurisdiction: {
    name: "",
    code: "",
    statusCategories: [],
  },
  isCitizenship: false,
  country: "",
  statusCategory: {
    _id: "",
    _type: "",
    name: "",
    isCitizenship: false,
    statusTypes: [],
  },
  statusType: {
    _id: "",
    _type: "",
    name: "",
    intructions: "<p>These are your instructions.</p>",
    dataCollectors: [],
    documentCollectors: [],
  },
  passport_number: "",
  passport_issue_country: "",
  date_of_birth: new Date().toDateString(),
  bratwursttype: "",
};

const questionnaireFormSlice = createSlice({
  name: "questionnaire-form",
  initialState,
  reducers: {
    updateForm: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;
      // For nested objects in the data
      const keys = key.split("."); // if key is "data.key"
      return produce(state, (draftState) => {
        let obj = draftState;
        for (let i = 0; i < keys.length - 1; i++) {
          obj = obj[keys[i]] || (obj[keys[i]] = {});
        }
        obj[keys[keys.length - 1]] = value;
      });
    },
    setFormData: (state, action: PayloadAction<FormState>) => {
      return action.payload;
    },
  },
});

export const { updateForm, setFormData } = questionnaireFormSlice.actions;
export default questionnaireFormSlice.reducer;
