import { ComponentProps } from "react";
import { cn } from "src/utils/tailwindMerger";

type HeadingProps = ComponentProps<"h2"> & {
  className?: string;
  size?: "sm" | "base" | "lg" | "xl";
};

const Heading = ({ className, size, ...props }: HeadingProps) => {
  const baseHeadingStyles = " font-bold text-2xl ";

  const smallHeadingStyles = " text-sm text-xl";
  const largeHeadingStyles = " text-3xl";
  const extraLargeHeadingStyles = " text-4xl";

  return (
    <h2
      className={cn(
        "text-black",
        baseHeadingStyles,
        size === "sm" && smallHeadingStyles,
        size === "lg" && largeHeadingStyles,
        size === "xl" && extraLargeHeadingStyles,

        className
      )}
      {...props}
    >
      {props.children}
    </h2>
  );
};

export default Heading;
