import { useEffect, useState } from "react";
import SuccessCheckmark from "../success-checmark/SuccessCheckMark";

interface SuccessMessageProps {
  label?: string;
  heading?: string;
}

export const SuccessMessage = ({ heading, label }: SuccessMessageProps) => {
  const [showSuccessMark, setShowSuccessMark] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowSuccessMark(true);
    }, 500);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(
        130deg,
        #E0FFF3 60%,
        #E0FFF300
      ),
      url("/images/success-background.png")`,
      }}
      className="w-full  flex-shrink-0 rounded-xl p-12 w-full md:bg-contain bg-cover bg-right-bottom bg-no-repeat"
    >
      {
        // show success mark
        showSuccessMark && (
          <div className="flex w-full justify-center">
            <SuccessCheckmark />
          </div>
        )
      }

      <div className="text-black font-inter text-3xl font-normal leading-normal ">
        <p className="text-center font-bold mt-3">{heading}</p>
      </div>
      <div className="text-black font-inter text-xl font-normal leading-normal mt-4">
        {label}
      </div>
    </div>
  );
};
