import React from "react";
import { Title } from "@simplecitizen/gdl-react-ui-components";
import { useAppDispatch } from "src/hooks/redux-hooks";
import {
  removeIdentityDoc,
  setDocCaptureData,
} from "src/store/slices/identityDocCapture";
import DocumentUploaderCard from "./DocumentUploaderCard";
import { removeDoc } from "src/store/slices/uploadedNonIdDocsSlice";

interface RequiredDocumentsProps {
  selectedStatusType: StatusType | null;
  documentCollectors: DocumentCollector[] | null;
  setDocumentCollectors: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
}

const RequiredDocuments = ({
  selectedStatusType,
  documentCollectors,
  setDocumentCollectors,
}: RequiredDocumentsProps) => {
  const dispatch = useAppDispatch();
  dispatch(
    setDocCaptureData({
      getDocumentCollector: selectedStatusType?.documentCollectors,
    })
  );

  const removeDocument = (
    document: DocumentCollector,
    docToRemove: "nonIdentityDocuments" | "identityDocuments"
  ) => {
    setDocumentCollectors((prev) => {
      if (prev) {
        return prev.map((doc) => {
          if (doc._id === document?._id) {
            return {
              ...doc,
              [docToRemove]: undefined,
            };
          }
          return doc;
        });
      }
      return null;
    });
    dispatch(removeDoc(document));
    dispatch(removeIdentityDoc(document));
  };
  return (
    <div>
      <Title className="mt-3 mb-3">Required documents</Title>
      <div className="flex flex-col gap-y-6">
        {selectedStatusType &&
          documentCollectors &&
          documentCollectors?.map((document) => (
            <DocumentUploaderCard
              removeDocument={removeDocument}
              key={document._id}
              document={document}
            />
          ))}
      </div>
    </div>
  );
};

export default RequiredDocuments;
