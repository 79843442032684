import { ComponentProps, forwardRef } from "react";
import { cn } from "src/utils/tailwindMerger";

type GrayCardProps = ComponentProps<"div"> & {
  className?: string;
  noPadding?: boolean;
};

const GrayCard = forwardRef<HTMLDivElement, GrayCardProps>(
  ({ className, noPadding = false, ...props }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          boxShadow: "0px 0px 0px 0.2px rgb(0 0 0 / 0.2)",
          ...props.style,
        }}
        className={cn("bg-neutral rounded-xl", !noPadding && "p-4", className)}
        {...props}
      />
    );
  }
);

export default GrayCard;
