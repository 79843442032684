import React, { useEffect, useState } from "react";
import Heading from "../heading/Heading";
import TermContent from "./TermContent";

interface TermContentFullScreenProps {
  term: Term;
}

const TermContentFullScreen = ({ term }: TermContentFullScreenProps) => {
  const [h1Content, setH1Content] = useState("");
  const [termsContent, setTermsContent] = useState("");

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(term.contents, "text/html");
    const h1 = doc.querySelector("h1");
    const h1Text = h1 ? h1.textContent : "";
    if (h1) {
      h1.remove();
    }
    const remainingHTML = doc.body.innerHTML;
    setH1Content(h1Text!);
    setTermsContent(remainingHTML);
  }, [term.contents]);
  return (
    <div>
      <Heading className="mb-3">{h1Content}</Heading>
      <TermContent content={termsContent} />
    </div>
  );
};

export default TermContentFullScreen;
