import {
  Title,
  SegmentedRadioButton,
} from "@simplecitizen/gdl-react-ui-components";
import { useAppDispatch, useAppSelector } from "src/hooks/redux-hooks";
import { clearIdDocs } from "src/store/slices/identityDocCapture";
import { clearNonIdDocs } from "src/store/slices/uploadedNonIdDocsSlice";

interface StatusTypeProps {
  selectedStatusType: StatusType | null;
  selectedCategory: StatusCategory;
  selectedStatusTypeId: string | null;
  setSelectedStatusTypeId: React.Dispatch<React.SetStateAction<string | null>>;
}

const StatusType = ({
  selectedCategory,
  selectedStatusTypeId,
  setSelectedStatusTypeId,
  selectedStatusType,
}: StatusTypeProps) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  return (
    <div>
      <div className="w-full mt-3 pb-6">
        <Title>Work Status</Title>
        <div className="mt-3">
          <div className="grid  gap-2 ">
            {selectedStatusType ? (
              <div>
                <SegmentedRadioButton
                  activeColor={client?.branding?.primaryColor || "#0A71C7"}
                  name="status_type"
                  id={selectedStatusType._id}
                  value={selectedStatusType._id}
                  label={selectedStatusType.name}
                  selectedValue={selectedStatusTypeId}
                  onChange={(e) =>
                    setSelectedStatusTypeId(e.target.value as string)
                  }
                >
                  <div className="flex flex-col">
                    <div className="font-semibold">
                      {selectedStatusType.name}
                    </div>

                    {selectedStatusType?.description && (
                      <div
                        className="text-sm prose"
                        dangerouslySetInnerHTML={{
                          __html: selectedStatusType?.description,
                        }}
                      />
                    )}
                  </div>
                </SegmentedRadioButton>

                <div
                  role="button"
                  onClick={() => {
                    setSelectedStatusTypeId(null);
                    dispatch(clearIdDocs());
                    dispatch(clearNonIdDocs());
                  }}
                  style={{
                    color: client?.branding?.primaryColor || "#0A71C7",
                  }}
                  className="text-primary-blue font-semibold mt-2 md:cursor-pointer text-sm underline"
                >
                  Select other status type
                </div>
                {selectedStatusType?.instructions && (
                  <div>
                    <Title className="mb-1 mt-4">Instructions</Title>
                    <div>
                      <div
                        className="text-sm prose"
                        dangerouslySetInnerHTML={{
                          __html: selectedStatusType.instructions,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {selectedCategory.statusTypes?.map((statusType) => (
                  <SegmentedRadioButton
                    key={statusType._id}
                    activeColor={client?.branding?.primaryColor || "#0A71C7"}
                    name="status_type"
                    id={statusType._id}
                    value={statusType._id}
                    selectedValue={selectedStatusTypeId}
                    onChange={(e) =>
                      setSelectedStatusTypeId(e.target.value as string)
                    }
                  >
                    <div className="flex flex-col">
                      <div className="font-semibold">{statusType.name}</div>

                      {statusType?.description && (
                        <div
                          className="text-sm prose"
                          dangerouslySetInnerHTML={{
                            __html: statusType?.description,
                          }}
                        />
                      )}
                    </div>
                  </SegmentedRadioButton>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusType;
