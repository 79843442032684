import React, { useEffect, useMemo } from "react";
import { Title, TextInput } from "@simplecitizen/gdl-react-ui-components";
import { useAppSelector } from "src/hooks/redux-hooks";
import { useController } from "react-hook-form";

const BioGraphical = ({ control }: { control: any }) => {
  const profile = useAppSelector((state) => state.profileData);
  const { field: firstNameField } = useController({
    name: "firstName",
    control,
  });
  const { field: middleNameField } = useController({
    name: "middleName",
    control,
  });

  const { field: lastNameField } = useController({
    name: "lastName",
    control,
  });

  useEffect(() => {
    if (profile) {
      firstNameField.onChange(profile.profile.name.firstName);
      middleNameField.onChange(profile.profile.name.middleName);
      lastNameField.onChange(profile.profile.name.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  const fields = useMemo(() => {
    return [
      {
        label: "First name",
        disabled: true,
        defaultValue: profile.profile.name.firstName,
        ...firstNameField,
      },
      {
        label: "Middle name",
        disabled: false,
        defaultValue: profile.profile.name.middleName,
        ...middleNameField,
      },
      {
        label: "Last name",
        defaultValue: profile.profile.name.lastName,
        disabled: true,
        ...lastNameField,
      },
    ];
  }, [firstNameField, middleNameField, lastNameField, profile]);

  return (
    <div className="w-full">
      <Title>Biographical Information</Title>
      <div className="mt-3">
        {fields.map(({ name, ...field }) => (
          <TextInput
            key={name}
            className=""
            labelClassName="font-normal"
            name={name}
            {...field}
          />
        ))}
      </div>
    </div>
  );
};

export default BioGraphical;
