import { ReactNode, ComponentProps, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type ContentWrapProps = ComponentProps<"div"> & {
  children: ReactNode;
  className?: string;
};

const ContentWrapper = forwardRef<HTMLDivElement, ContentWrapProps>(
  ({ children, className = "", ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          " px-2  md:!px-[2rem] mx-auto w-full max-w-7xl  overflow-hidden",
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default ContentWrapper;
