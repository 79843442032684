import React, { useCallback, useEffect, useRef } from "react";
import { PageWrapper } from "src/components";
import { LandingPage } from "./landing_page";
import { TsAndCsPage } from "./ts_and_cs_page";
import { QuestionnairePage } from "./questionnaire_page";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { getQuestionnaire } from "src/services/apiService";
import { fetchData } from "src/store/slices/questionnaireSlice";
import { nextPage } from "src/store/slices/pageSlice";
import { SuccessPage } from "./success_page";
import { useParams } from "react-router-dom";
import Loader from "src/components/lib/loader/Loader";
import { CheckExpiredMessage } from "src/components/lib/error-messages/CheckExpired";
import ContentWrapper from "src/components/lib/content-wrapper/ContentWrapper";

const CheckPages = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { checkId } = useParams<{ checkId: string }>();

  const dispatch = useDispatch();
  const fetcher = useCallback(
    async (id: string) => {
      return await getQuestionnaire(id, dispatch);
    },
    [dispatch]
  );

  const { data, isLoading, error } = useSWR(checkId, fetcher, {
    revalidateOnFocus: false,
  });

  const landingPageRef = useRef<HTMLDivElement>(null);
  const tsAndCsPageRef = useRef<HTMLDivElement>(null);
  const questionnairePageRef = useRef<HTMLDivElement>(null);
  const successPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data !== null) {
      dispatch(fetchData(data));
    }
  }, [data, dispatch]);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const page_no = useSelector((state: any) => state?.page_number.page || "");

  useEffect(() => {
    if (page_no === 2) {
      scrollToSection(tsAndCsPageRef);
    } else if (page_no === 3) {
      scrollToSection(questionnairePageRef);
    } else if (page_no === 4) {
      scrollToSection(successPageRef);
    }
  }, [page_no]);

  const loadSpinner = () => {
    return (
      <div className="flex items-center justify-center h-screen md:min-h-[70vh]">
        <Loader />
      </div>
    );
  };

  if (isLoading) {
    return loadSpinner();
  }

  if (error) {
    const errorMessage = error?.response?.data?.message;
    const errorStatus = error?.response?.status;

    return (
      <ContentWrapper className="flex items-center justify-center h-screen w-screen">
        <CheckExpiredMessage label={errorMessage} heading={errorStatus} />
      </ContentWrapper>
    );
  }

  return (
    <PageWrapper ref={containerRef}>
      <div ref={landingPageRef} className="h-screen overflow-auto  ">
        <LandingPage
          scrollToNextPage={() => {
            dispatch(nextPage());
            setTimeout(() => {
              landingPageRef.current?.classList.add("hidden");
              questionnairePageRef.current?.classList.add("hidden");
            }, 3000);
          }}
        />
      </div>

      <div ref={tsAndCsPageRef} className="h-full overflow-auto ">
        {page_no === 2 && (
          <TsAndCsPage
            scrollToNextPage={() => {
              questionnairePageRef.current?.classList.remove("hidden");
              dispatch(nextPage());
              setTimeout(() => {
                tsAndCsPageRef.current?.classList.add("hidden");
              }, 3000);
            }}
          />
        )}
      </div>

      <div ref={questionnairePageRef} className="h-screen overflow-auto ">
        <QuestionnairePage
          scrollToNextPage={() => {
            dispatch(nextPage());
            setTimeout(() => {
              questionnairePageRef.current?.classList.add("hidden");
            }, 3000);
          }}
        />
      </div>

      <div ref={successPageRef} className="h-screen overflow-auto ">
        {page_no === 4 && <SuccessPage />}
      </div>
    </PageWrapper>
  );
};

export default CheckPages;
