import React from "react";
import { cn } from "../../../utils/tailwindMerger";

interface TermContentProps {
  classsName?: string;
  content: string;
}

const TermContent = ({ classsName, content }: TermContentProps) => {
  return (
    <div
      className={cn("", classsName)}
      style={{ scrollbarWidth: "thin", scrollbarColor: "#888 #e0e0e0" }}
    >
      <div id={``} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default TermContent;
