import {
  Title,
  SegmentedRadioButton,
} from "@simplecitizen/gdl-react-ui-components";
import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux-hooks";
import { clearIdDocs } from "src/store/slices/identityDocCapture";
import { clearNonIdDocs } from "src/store/slices/uploadedNonIdDocsSlice";

interface StatusCategoryProps {
  selectedStatusCategoryId: string | null;
  selectedCategory: StatusCategory | null;
  selectedCitizenShip: "citizen" | "not_citizen" | null;
  setSelectedStatusType: React.Dispatch<
    React.SetStateAction<StatusType | null>
  >;
  setSelectedStatusCategoryId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<StatusCategory | null>
  >;
  resetStates?: () => void;
}

const StatusCategory = ({
  selectedStatusCategoryId,
  setSelectedStatusCategoryId,
  selectedCitizenShip,
  setSelectedStatusType,
  selectedCategory,
  setSelectedCategory,
  resetStates,
}: StatusCategoryProps) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  const statusCategories = useAppSelector(
    (state) =>
      state.questionnaire_data.questionnaire?.jurisdiction.statusCategories
  );

  const selectedStatusCategories = useMemo(() => {
    if (selectedCitizenShip === "citizen") {
      return statusCategories?.filter((category) => category.isCitizenship);
    }
    return statusCategories?.filter((category) => !category.isCitizenship);
  }, [selectedCitizenShip, statusCategories]);

  return (
    <div>
      <div className="w-full mt-6 pb-6">
        <Title>{"Work Status Category"}</Title>
        <div className="mt-3">
          <div>
            <div className="grid  gap-2 w-full mt-4">
              {selectedCategory ? (
                <div>
                  <SegmentedRadioButton
                    activeColor={client?.branding?.primaryColor || "#0A71C7"}
                    name="category"
                    onChange={(e) => {
                      setSelectedStatusCategoryId(e.target.value as string);
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="font-semibold">
                        {selectedCategory.name}
                      </div>

                      {selectedCategory?.description && (
                        <div
                          className="text-sm prose"
                          dangerouslySetInnerHTML={{
                            __html: selectedCategory?.description,
                          }}
                        />
                      )}
                    </div>
                  </SegmentedRadioButton>
                  <div
                    role="button"
                    onClick={() => {
                      resetStates && resetStates();
                      dispatch(clearIdDocs());
                      dispatch(clearNonIdDocs());
                    }}
                    style={{
                      color: client?.branding?.primaryColor || "#0A71C7",
                    }}
                    className="text-primary-blue font-semibold mt-2 md:cursor-pointer text-sm underline"
                  >
                    Select other status category
                  </div>
                </div>
              ) : (
                <>
                  {selectedStatusCategories?.map((category) => (
                    <SegmentedRadioButton
                      key={category._id}
                      activeColor={client?.branding?.primaryColor || "#0A71C7"}
                      name="category"
                      id={category._id}
                      value={category._id}
                      selectedValue={selectedStatusCategoryId}
                      onChange={(e) =>
                        setSelectedStatusCategoryId(e.target.value as string)
                      }
                    >
                      {
                        <div className="flex flex-col">
                          <div className="font-semibold">{category.name}</div>

                          {category?.description && (
                            <div
                              className="text-sm prose"
                              dangerouslySetInnerHTML={{
                                __html: category?.description,
                              }}
                            />
                          )}
                        </div>
                      }
                    </SegmentedRadioButton>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCategory;
