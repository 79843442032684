import { createSlice } from "@reduxjs/toolkit";

interface StatusState {
  statusCategory?: StatusCategory | null;
  statusType?: StatusType | null;
}

const initialState: StatusState = {
  statusCategory: {} as StatusCategory,
  statusType: {} as StatusType,
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatusType: (state, action) => {
      state.statusType = action.payload;
    },
    setStatusCategory: (state, action) => {
      state.statusCategory = action.payload;
    },
    setStatus: (state, action) => {
      state.statusCategory = action.payload.statusCategory;
      state.statusType = action.payload.statusType;
    },
  },
});

export const { setStatus, setStatusCategory, setStatusType } =
  statusSlice.actions;
export default statusSlice.reducer;
