import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../../../../utils/tailwindMerger";
import { formatString } from "../../../../utils/utils";
import { ComponentProps } from "react";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export type InputFieldProps = ComponentProps<"input"> & {
  error: string;
  icon?: IconDefinition;
  handleRemoveOption?: (option: Option) => void;
  selectedOptions?: Option[];
  label?: string;
  type?: "email" | "text";
  disabled?: boolean;
  register: RegisterFn;
  className?: string;
  onIconClick?: () => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      selectedOptions = [],
      name,
      handleRemoveOption,
      error,
      placeholder,
      icon,
      label,
      type = "text",
      disabled,
      className,
      register,
      onIconClick,
      handleChange,
      ...props
    },
    ref
  ) => {
    const newPlaceholder =
      placeholder ?? "Enter your " + (label || formatString(name ?? ""));

    const { onChange: registerOnChange, ...inputRegister } = register(
      name ?? ""
    );
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (registerOnChange) registerOnChange(e);
      if (handleChange) handleChange(e);
    };

    return (
      <div
        className={cn(
          `flex w-full h-3xl rounded-lg outline-none focus:ring-1 border text-xs font-normal capitalize bg-white shadow-[2px_4px_4px_rgba(0,0,0,0.13)] p-2`,
          error
            ? "border-red-500 ring-red-500"
            : disabled
              ? "border-[#949494] ring-[#949494] text-[#949494] bg-[#fafafa]"
              : "border-[#777777] ring-[#777777] text-black",
          className
        )}
      >
        <div className="w-full flex flex-wrap gap-1">
          {selectedOptions.length > 0 &&
            selectedOptions.map((option, i) => (
              <div
                key={i}
                className="text-steel bg-[#E6E6E6]   rounded  flex overflow-hidden gap-1"
              >
                <span className="flex-1 py-[0.5px] pl-2">{option.label}</span>{" "}
                <div
                  onClick={() => {
                    handleRemoveOption && handleRemoveOption(option);
                  }}
                  role="button"
                  className="cursor-pointer hover:bg-red-300 hover:text-red-600 px-1 flex justify-center items-center"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            ))}

          <input
            ref={ref}
            className="flex-1 outline-none border-none bg-transparent"
            type={type}
            placeholder={newPlaceholder}
            disabled={disabled}
            {...inputRegister}
            onChange={handleOnChange}
            {...props}
          />
        </div>

        {icon && (
          <div role="button" onClick={onIconClick}>
            <FontAwesomeIcon icon={icon} className="text-steel" />
          </div>
        )}
      </div>
    );
  }
);

InputField.displayName = "InputField";
