import { ComponentProps } from "react";
import { cn } from "src/utils/tailwindMerger";

type WhiteCardProps = ComponentProps<"div"> & {
  className?: string;
  noPadding?: boolean;
};

const WhiteCard = ({
  className,
  noPadding = false,
  ...props
}: WhiteCardProps) => {
  return (
    <div
      style={{
        boxShadow: "0px 0px 0px 0.2px rgb(0 0 0 / 0.2)",
        ...props.style,
      }}
      className={cn("bg-white  rounded-xl", !noPadding && "p-4", className)}
      {...props}
    />
  );
};

export default WhiteCard;
