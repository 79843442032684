import {
  SearchDropDown,
  TextInput,
  Title,
  DatePicker,
  InfoBanner,
  Button,
  ErrorBlock,
  Alert,
} from "@simplecitizen/gdl-react-ui-components";
import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/redux-hooks";
import { countries } from "src/utils/countries";
import { isValidDate } from "src/utils/utils";

interface VevoProps {
  onSubmitVevo: (vevoData: VevoType) => Promise<void>;
  selectedCitizenShip?: "citizen" | "not_citizen" | null;
  isSubmittingVevo: boolean;
  vevoApiError: string | null;
  isVevoDone: boolean;
}

const Vevo = ({
  onSubmitVevo,
  isSubmittingVevo,
  selectedCitizenShip,
  vevoApiError,
  isVevoDone,
}: VevoProps) => {
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  const [dateOfBirth, setDateOfBirth] = useState<Date | string>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [passPortNumberError, setPassPortNumberError] = useState<string>("");
  const [passPortNumber, setPassPortNumber] = useState<string>("");
  const [passPortIssueCountry, setPassPortIssueCountry] = useState<string>("");
  const [passPortIssueCountryError, setPassPortIssueCountryError] =
    useState<string>("");

  const vevo_fields = useMemo(() => {
    return [
      {
        label: "Passport Number",
        name: "passportNumber",
        type: "text",
        error_message: passPortNumberError,
        value: passPortNumber,
        pattern: "[a-zA-Z0-9]/g",

        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          // Allow only alphanumeric characters (a-z, A-Z, 0-9)
          const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
          setPassPortNumber(alphanumericValue);
        },
      },
      {
        label: "Passport Issue Country",
        name: "passportIssueCountry",
        type: "dropdown",
        options: countries,
        value: passPortIssueCountry,
        hidden: selectedCitizenShip === "citizen",
        error_message: passPortIssueCountryError,
        onSelect: (e: Option) => {
          setPassPortIssueCountry(e.label);
        },
      },
      {
        label: "Date of Birth",
        name: "dateOfBirth",
        type: "date",
        error_message: dateOfBirthError,
        value: isValidDate(dateOfBirth?.toString() || "")
          ? (dateOfBirth as Date)
          : new Date(),
        onDateChange: (date: Date) => {
          setDateOfBirth(date);
        },
      },
    ];
  }, [
    dateOfBirthError,
    passPortIssueCountryError,
    passPortNumberError,
    dateOfBirth,
    passPortIssueCountry,
    passPortNumber,
    selectedCitizenShip,
  ]);

  const onSubmit = async () => {
    if (!dateOfBirth) {
      setDateOfBirthError("Date of Birth is required");
      return;
    }
    if (!passPortNumber || passPortNumber.trim() === "") {
      setPassPortNumberError("Passport Number is required");
      return;
    }

    if (
      selectedCitizenShip === "not_citizen" &&
      (!passPortIssueCountry || passPortIssueCountry.trim() === "")
    ) {
      setPassPortIssueCountryError("Passport Issue Country is required");
      return;
    }
    const vevoData = {
      dateOfBirth:
        typeof dateOfBirth === "string"
          ? dateOfBirth
          : dateOfBirth.toISOString(),
      passportCountryCode: passPortIssueCountry,
      passPortNumber,
    };
    onSubmitVevo(vevoData);
  };

  return (
    <div className="w-full mt-6">
      <Title className="mb-3">
        {selectedCitizenShip === "citizen" ? "Citizenship Check" : "VEVO Check"}
      </Title>
      {isVevoDone ? (
        <div>
          <Alert className="py-2 px-3 flex items-center" type="success">
            <div className="flex gap-2 items-center h-full">
              <div className="">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>

              <div className="flex-1"> VEVO check Successful</div>
            </div>
          </Alert>
        </div>
      ) : (
        <>
          <div className=" border-b border-b-slate-300 pb-5 mb-8">
            {vevo_fields.map(({ name, type, hidden, ...field }) => (
              <div key={name}>
                {type === "text" && !hidden && (
                  <TextInput
                    className=""
                    labelClassName="font-normal "
                    {...field}
                  />
                )}
                {type === "dropdown" && !hidden && (
                  <SearchDropDown
                    color={client?.branding?.primaryColor || "#0A71C7"}
                    onChange={(e: any) =>
                      setPassPortIssueCountry(e.target.value)
                    }
                    onClear={() => setPassPortIssueCountry("")}
                    onError={() => setPassPortIssueCountry("")}
                    // initialValue={field.value}
                    resultsContainerClassName="top-[4.4rem]"
                    inputClassName=""
                    label={field.label}
                    name={name}
                    className="-mt-2"
                    labelClassName="font-normal"
                    options={field.options}
                    onSelect={field.onSelect}
                    error_message={field.error_message}
                  />
                )}
                {type === "date" && !hidden && (
                  <DatePicker
                    className="-mt-2"
                    color={client?.branding?.primaryColor || "#0A71C7"}
                    labelClassName="font-normal"
                    onDateChange={field.onDateChange!}
                    error_message={field.error_message}
                    value={field.value as Date}
                    label={field.label}
                  />
                )}
              </div>
            ))}
          </div>
          <InfoBanner>
            <div>
              By submitting this form, you confirm that you agree to the{" "}
              <a
                href="https://www.vevo.gov.au/"
                target="_blank"
                rel="noreferrer"
                style={{ color: client?.branding?.primaryColor || "#0A71C7" }}
                className="font-bold"
              >
                VEVO Terms and Conditions.
              </a>
            </div>
          </InfoBanner>
          {vevoApiError && (
            <ErrorBlock className="mt-6">
              <div className="font-bold">VEVO Check was unsuccessful</div>
              <div>{vevoApiError}</div>
            </ErrorBlock>
          )}

          <Button
            disabled={
              isSubmittingVevo ||
              !dateOfBirth ||
              !passPortNumber ||
              (selectedCitizenShip === "not_citizen" && !passPortIssueCountry)
            }
            className="w-full mt-8"
            type="button"
            onClick={onSubmit}
            color={client?.branding?.primaryColor || "#0A71C7"}
          >
            {isSubmittingVevo
              ? "Submitting Vevo Check...."
              : selectedCitizenShip === "citizen"
                ? "Submit Check"
                : "Submit VEVO Check"}
          </Button>
        </>
      )}
    </div>
  );
};

export default Vevo;
