import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux-hooks";
import axios from "axios";
import { DocumentUpload } from "@simplecitizen/gdl-react-ui-components";
import { CameraFacingMode } from "@regulaforensics/vp-frontend-document-components";

type ErrorObject = {
  [key: string]: {
    en: string;
  };
};

export type FieldError = {
  fieldName: string;
  error: string;
};

const MAX_UPLOAD_ATTEMPTS = parseInt(
  process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "3",
  10
);

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5003/api/workright";

const DocumentUploadMobileHandoff = () => {
  const [searchParams] = useSearchParams();
  const startScreen = searchParams.get("startScreen");
  const facialComparison = searchParams.get("facialComparison");
  const documentName = searchParams.get("documentName");

  /**
   * Converts a string by removing "data.", dots, and hyphens, and converting to lowercase.
   * @param {string} str - The string to convert.
   * @returns {string} - The processed string in lowercase without "data.", dots, or hyphens.
   */
  function toLowerNoSeparators(str: string) {
    return str
      .replace(/^data[.-]/, "")
      .replace(/[.-]/g, "")
      .toLowerCase();
  }

  /**
   * Converts an object of errors to an array of FieldError objects.
   * @param {ErrorObject} errors - The error object to convert.
   * @returns {FieldError[]} - Array of FieldError objects.
   */
  function convertErrorsToFieldErrors(errors: ErrorObject): FieldError[] {
    return Object.keys(errors).map((key) => ({
      fieldName: toLowerNoSeparators(key),
      error: errors[key].en,
    }));
  }
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  const { checkId, docId, sessionId } = useParams<{
    checkId: string;
    docId: string;
    sessionId: string;
  }>();

  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [startOnRegulaUploader, setStartOnRegulaUploader] = useState(true);
  const [documentUploaded, setDocumentUploaded] = useState(false);

  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const verifyDocument = async ({ docFrontSideImage }: any) => {
    // setDocKey(null);
    setServerErrors([]);
    setFieldErrors([]);
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage }],
      relationships: [
        {
          type: "mobile-handoff-session",
          _id: sessionId,
        },
      ],
    };

    try {
      await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/Validate`,
        newDocument
      );
      setDocumentUploaded(true);
      //   setDocKey(response.data._id);
    } catch (error: any) {
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );
        console.log({ field_errors });
        setFieldErrors(field_errors);
      }

      //   handleApiErrors(error.response.data);
    }
  };

  return (
    <div className="h-full w-full flex items-center">
      <DocumentUpload
        facialComparison={facialComparison === "true"}
        uploadAttempts={MAX_UPLOAD_ATTEMPTS}
        regulaStartScreen={startScreen === "true"}
        // regulaStartScreen={true}
        onStoreRegulaDocument={async (fields, rawDocument) => {
          setStartOnRegulaUploader(false);
        }}
        onForceRegulaDocument={async (fields, rawDocument) => {
          setStartOnRegulaUploader(false);
          setDocumentUploaded(true);
        }}
        startOnRegulaUploader={startOnRegulaUploader}
        isDocumentUploadedOnMobile={documentUploaded}
        regula_settings={{
          regulaLogo: false,
          changeCameraButton: true,
          cameraMode: "environment" as CameraFacingMode,
          multipageProcessing: true,
        }}
        fieldErrors={fieldErrors}
        onReplace={() => {}}
        verifyDocument={async ({ docFrontSideImage }, rawData) => {
          const base64Data = docFrontSideImage.split(",")[1];
          // setUploadedImage(base64Data);
          await verifyDocument({ docFrontSideImage: base64Data });
        }}
        verifyOnProcessingDocument={true}
        documentReaderProcessParams={{
          returnUncroppedImage: false,
        }}
        title={`Upload ${documentName}`}
        documentName={`${documentName}`}
        onRemoveDocument={() => {
          // if (document._subtype === "identity") {
          //   return removeDocument(document, "identityDocuments");
          // }
          // removeDocument(document, "nonIdentityDocuments");
        }}
        // onFileChange={async (files) => {
        //   await handleFileChange(files);
        // }}
        // uploadProgress={uploadProgress}
        onStoreDefaultDocument={async (docData) => {
          console.log({ docData });
          // await submitNonIdDoc(docData);
        }}
        serverErrors={serverErrors!}
        identityType={"identity"}
        color={
          client && client?.branding?.primaryColor
            ? client?.branding?.primaryColor
            : "#14BDF3"
        }
      />
    </div>
  );
};

export default DocumentUploadMobileHandoff;
