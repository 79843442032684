import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ErrorState {
  status: number;
  message: string;
  endpoint: string;
}

const initialState: ErrorState = {
  status: 0,
  message: "",
  endpoint: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorData: (state, action: PayloadAction<Partial<ErrorState>>) => {
      // Only update the fields provided in the action payload
      Object.assign(state, action.payload);
    },
  },
});
export const { setErrorData } = errorSlice.actions;
export default errorSlice.reducer;
