import { createSlice } from "@reduxjs/toolkit";

export interface UploadedNonIdDocs {
  docs: any;
  requiredDocs?: DocumentCollector[];
  selectedStatusType: StatusType | null;
  selectedStatusCategory?: StatusCategory | null;
}

const initialState: UploadedNonIdDocs = {
  docs: [],
  requiredDocs: [],
  selectedStatusType: null,
};

export const uploadedNonIdDocsSlice = createSlice({
  name: "uploadedNonIdDocs",
  initialState,
  reducers: {
    selectStatusType: (state, action) => {
      state.selectedStatusType = action.payload;
    },
    selectStatusCategory: (state, action) => {
      state.selectedStatusCategory = action.payload;
    },

    addDoc: (state, action) => {
      if (state?.docs?.length === 0) {
        state.docs = [action.payload];
      } else {
        const isDocAlreadyUploaded = state.docs?.some(
          (doc: any) => doc._id === action.payload._id
        );
        if (!isDocAlreadyUploaded) {
          state.docs = [...state.docs!, action.payload];
        } else {
          state.docs = state.docs?.map((doc: any) =>
            doc._id === action.payload._id ? action.payload : doc
          );
        }
        // state.docs = state.docs?.map((doc) =>
        //   doc._id === action.payload._id ? action.payload : doc
        // );
      }
    },
    removeDoc: (state, action) => {
      state.docs = state.docs?.filter(
        (doc: any) => doc._id !== action.payload._id
      );
    },
    clearNonIdDocs: (state) => {
      state.docs = [];
    },
    setRequiredDocs: (state, action) => {
      state.requiredDocs = action.payload;
    },
  },
});
export const {
  addDoc,
  removeDoc,
  setRequiredDocs,
  clearNonIdDocs,
  selectStatusType,
  selectStatusCategory,
} = uploadedNonIdDocsSlice.actions;

export default uploadedNonIdDocsSlice.reducer;
