import { createSlice } from "@reduxjs/toolkit";

interface QuestionnaireState {
  questionnaire?: Questionnaire | null;
  loading: boolean;
  error: any;
}

const initialState: QuestionnaireState = {
  questionnaire: null,
  loading: false,
  error: null,
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    fetchStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchData(state, action) {
      state.questionnaire = action.payload;
      state.loading = false;
    },
    fetchFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStart, fetchData, fetchFailure } =
  questionnaireSlice.actions;
export default questionnaireSlice.reducer;
